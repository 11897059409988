<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{title}} exam checklist
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \ <router-link to="/score">Score </router-link> \
                  Exam checklist
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                    v-model="exam_checklist.box_name"
                    outlined
                    :error="$v.exam_checklist.box_name.$error"
                    dense
                >
                  <template v-slot:label>
                    Widget title <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.exam_checklist.box_name.$error">This information is required</span>
                <span class="text-danger" v-if="errors.box_name" >* {{ errors.box_name[0] }}</span>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    v-model="exam_checklist.box_button_name"
                    outlined
                    :error="$v.exam_checklist.box_button_name.$error"
                    dense
                >
                  <template v-slot:label>
                    Widget button text <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.exam_checklist.box_button_name.$error">This information is required</span>
                <span class="text-danger" v-if="errors.box_button_name" >* {{ errors.box_button_name[0] }}</span>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    v-model="exam_checklist.name"
                    :error="$v.exam_checklist.name.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Checklist page title <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.exam_checklist.name.$error">This information is required</span>
                <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                Widget text <span class="text-danger">*</span>
                <ckeditor
                    :config="editorConfig"
                    v-model="exam_checklist.box_description"
                >
                </ckeditor>
                <span class="text-danger" v-if="$v.exam_checklist.box_description.$error">This information is required</span>
                <span class="text-danger" v-if="errors.box_description" >* {{ errors.box_description[0] }}</span>
              </v-col>
              <v-col cols="6">
                Page text <span class="text-danger">*</span>
                <ckeditor
                    :config="editorConfig2"
                    v-model="exam_checklist.description"
                >
                </ckeditor>
                <span class="text-danger" v-if="$v.exam_checklist.description.$error">This information is required</span>
                <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
              </v-col>



              <v-col cols="12" md="6">
                Status
                <v-switch
                    v-model="exam_checklist.is_active"
                    :label="exam_checklist.is_active ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                    text
                    x-large
                    class="cancel-btn mr-2"
                    @click="close">
                  Cancel
                </v-btn>
                <v-btn
                    x-large
                    dark
                    @click="createOrUpdate()"
                    :loading="loading"
                >
                  {{title == 'Edit' ? 'Update' : 'Save' }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ExamChecklistService from "@/services/score/exam-checklist/ExamChecklistService";

const examChecklist = new ExamChecklistService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      loading: false,
      errors: [],
      exam_checklist: {
        box_name:'',
        box_description:'',
        box_button_name:'',
        name: '',
        score_id: '',
        description: '',
        is_active: true,
      },

      editorConfig: {
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ],
        allowedContent: true,
        versionCheck: false
      },
      editorConfig2: {
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ],
        allowedContent: true,
        versionCheck: false
      }
    }
  },
  validations: {
    exam_checklist: {
      box_name:{ required },
      box_description:{ required },
      box_button_name:{ required },
      name: { required },
      description: { required },
      is_active: { required }
    }
  },
  mounted() {
    this.exam_checklist.score_id = this.$route.params.scoreId;
    this.examChecklistDetail();
  },
  methods: {
    close(){
      this.$tabs.close();
      this.resetForm();
    },
    examChecklistDetail() {
      examChecklist
          .getByScore(this.exam_checklist.score_id)
          .then(response => {
            if(response.data.examChecklist != null) {
              this.title = "Edit";
              this.exam_checklist = response.data.examChecklist;
            }else {
              this.title = "Add";
            }
          })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        examChecklist
            .createOrUpdate(this.exam_checklist)
            .then(response =>{
              this.loading = false;
              this.$snotify.success("Exam checklist saved successfully");
              this.examChecklistDetail();
            })
            .catch((err) => {
              this.loading = false;
              this.$snotify.error("Oops something went wrong");
              this.errors = err.errors;
            });
      }
    },
    resetForm(){
      this.$v.$reset();
      this.exam_checklist = {
        name: '',
        description: '',
        score_id: '',
        is_active: true,
      }
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    }
  }
}
</script>

<style scoped>

</style>
