import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ExamChecklistService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/exam-checklist';
    }

    createOrUpdate(data) {
        let url = `${this.#api}/create-or-update`;
        return apiService.post(url, data)
    }

    getByScore(scoreId) {
        let url = `${this.#api}/get/${scoreId}`
        return apiService.get(url)
    }
}